import React from 'react';
import { navigate } from 'gatsby';
import { Box, Heading, Text, Button } from '@chakra-ui/react';

import NavBar from '../components/NavBar';
import Footer from '../components/Footer';

export default function NotFound() {
  return (
    <>
    <NavBar links={[]} />
    <Box minH="100vh" placeContent="center" display="grid">
      <Box textAlign="center" px={6}>
        <Heading
          display="inline-block"
          fontSize="8xl"
          backgroundImage="linear-gradient(100deg, var(--chakra-colors-brand-300), var(--chakra-colors-brand-600))"
          backgroundClip="text"
          fontWeight="900"
        >
          404
        </Heading>
        <Text fontWeight="600" fontSize={{base: "xl", lg: "2xl"}} mt={3} mb={2}>
          Page Not Found
        </Text>
        <Text fontSize={{base: "lg", lg: "xl"}} color={'gray.500'} mb={6}>
          The page you're looking for does not seem to exist
        </Text>
        <Button
          colorScheme="brand"
          bgGradient="linear(to-r, brand.400, brand.500, brand.600)"
          color="white"
          variant="solid"
          onClick={() => navigate('/')}
        >
          Return Home
        </Button>
      </Box>
      <Box w="100vw" position="absolute" bottom={0}>
        <Footer />
      </Box>
    </Box>
    </>
  );
}
